import {
  IClientAppointmentsData,
  IClientAppointmentsResponseData,
  IClientCalendarEvent,
  IClientCalendarEventResponse,
  IClientDetailsData,
  IClientDetailsInfoProps,
  IClientEventData,
  IClientReportsData,
  IClientReportsResponse,
  IClientReportsResponseData,
  ISelectClientDetails,
  IClientVisitReport,
  TClientDetailsItem,
} from '../interfaces/IClientDetails'
import { IActivity, IUserActivity, IUsersResponse } from '../interfaces/IUsers'
import {
  IAppointmentTypesData,
  IAppointmentDefault,
  IAppointmentList,
  IArrayOptions,
} from '../interfaces/IAppointments'
import {
  ACTION_FORMULAIRES,
  ACTION_HISTORIQUE,
  ACTION_NOUVEAU_RAPPORT,
  ACTION_NOUVEAU_RENDEZ_VOUS,
  datesInitialState,
} from '../constants/userData'
import { STATUS } from '../enums/common'
import {
  IContractData,
  IContractDetailsFields,
  IContractHistoryData,
  ICustomContract,
  IReclamationFormFields,
} from '../interfaces/IContracts'
import {
  IEngagementHistory,
  IHistoryTableRow,
  IOrderHistory,
  IRecapHistory,
  IRecapProducts,
  IRecapSummary,
} from '../interfaces/IHistory'
import {
  IVisitReportInformation,
  IVisitReportForm,
  IVisitReport,
} from '../interfaces/IVisitReports'
import { IOfflineSynchroData } from '../interfaces/IOffline'
import { VISIT_REPORT_CURRENT_VERSION } from '../constants/visitReport'
import { IHandledError, IResponseData } from '../interfaces/ICommonComponents'
import {
  IDashboardCaFilters,
  ITurnoverData,
  IVisitsCoverageData,
} from '../interfaces/IDashboard'
import { ISelectOption } from '../interfaces/IFilters'
import { getArraySequence } from '../utils/helpers'
import { DATA_INITIAL_STATE } from '../constants/api'
import { prepareAppointmentTypesOptions } from '../utils/appointments'

export const mockClientDetailsData: IClientDetailsData = {
  id: 123,
  cipCode: '9999999',
  clientCode: 'FR9999999',
  name: 'Pharmacie Test',
  email: 'asd@asd.com',
  address1: 'Rue des long pres',
  address2: '',
  zipCode: '92100',
  city: 'BOULOGNE BILLANCOURT',
  phoneNumber: '0170956400',
  faxNumber: '0170989564',
  phoneAgentName: 'JEANBLANC Albane',
  phoneAgentComment: 'Lorem ipsum dolores',
  fieldAgentName: 'BOND James',
  fieldAgentComment: 'Lorem ipsum dolores',
  gpsLongitude: 46.2276,
  gpsLatitude: 2.2137,
  potentiel1: 'Lorem ipsum dolores',
  potentiel2: 'Lorem ipsum dolores',
  potentiel3: 'Lorem ipsum dolores',
  qualification3: 'Lorem ipsum dolores',
  targetType2: 'lorem',
  sectoLabo: 'selecto',
  typeCible: 'type',
  cluster: 'cluster 1',
  uga: 'uga',
  palier2022: 'palier 2022',
  palier2021: 'palier 2021',
  objNbVisites: 1,
  decile: 1,
  potentialCa: 'pot ca',
  potentialBoites: 'pot boites',
  capping: 1,
  quantiteBgm2021Fy: 1,
  quantiteBgm2020Fy: 2,
  subscribeComptoir: true,
  subscribeCoach: false,
  withAppointment: false,
  withVisitReport: false,
  titulaire: {
    civiliteTitulaire: 'Mr',
    prenomTitulaire: 'Prenom',
    nomTitulaire: 'Nom',
  },
  kamEmail: 'email@test.com',
}

export const mockClientDetailsWithAppointmentsData1: TClientDetailsItem = {
  id: 124,
  cipCode: '9999999',
  name: 'Pharmacie Test',
  address1: 'Rue des long pres',
  address2: '',
  zipCode: '92100',
  city: 'BOULOGNE BILLANCOURT',
  phoneNumber: '0170956400',
  gpsLongitude: 46.2276,
  gpsLatitude: 2.2137,
  lastVisitReport: {},
  nextAppointmentDate: null,
  withAppointment: false,
  withVisitReport: false,
}

export const mockClientDetailsWithAppointmentsData2: TClientDetailsItem = {
  id: 123,
  cipCode: '9999999',
  name: 'Pharmacie Test',
  address1: 'Rue des long pres',
  address2: '',
  zipCode: '92100',
  city: 'BOULOGNE BILLANCOURT',
  phoneNumber: '0170956400',
  gpsLongitude: 46.2276,
  gpsLatitude: 2.2137,
  lastVisitReport: {},
  nextAppointmentDate: null,
  withAppointment: false,
  withVisitReport: false,
}

export const mockClientReport: IClientReportsResponse = {
  id: 10,
  date: '15.05.2021',
  eventType: 'type1',
  formationType: 'Formation Flash',
  outcome: 'Visite Sell-out',
  contactPerson: 'George',
}

export const mockProcessedClientReport: IClientEventData = {
  id: 10,
  startDate: '15.05.2021',
  eventType: 'type1',
  formationType: 'Formation Flash',
  outcome: 'Visite Sell-out',
  contactPerson: 'George',
}

export const mockClientInfo: IClientDetailsInfoProps = {
  name: '/albert einstein/i',
  address1: 'Rue des long pres',
  phoneNumber: '0170956400',
  cipCode: '123',
  contactPerson: [
    {
      name: 'Jon Snow',
      value: '12',
    },
  ],
  zipCode: '1234',
  gpsLatitude: 2.2137,
  gpsLongitude: 46.2276,
  city: 'Cluj-Napoca',
}

export const mockClientAppointmentsData: IClientAppointmentsData = {
  total: 1,
  data: [
    {
      id: 1,
      startDate: '2019-07-24T07:52:48.467Z',
      eventType: 'Visite argumentée',
      contactPerson: 'Muriel Gretchen',
    },
  ],
}

export const mockUserAppointmentsData: IClientCalendarEventResponse[] = [
  {
    id: 12,
    startDate: '2019-07-24T07:52:48.467Z',
    endDate: '2019-07-24T10:52:48.467Z',
    eventType: 'Visite argumentée',
    contactPerson: 'Muriel Gretchen',
    city: 'Paris',
    clientName: 'Jon Snow',
    cipCode: '1234',
  },
  {
    id: 13,
    startDate: '2019-07-24T07:52:48.467Z',
    endDate: '2019-07-24T10:52:48.467Z',
    eventType: 'Visite argumentée',
    contactPerson: 'Tony Stark',
    city: 'London',
    clientName: 'James Bond',
    cipCode: '1234',
  },
]

export const mockUserReportsData: IClientVisitReport[] = [
  {
    id: 12,
    startDate: '2019-07-24T07:52:48.467Z',
    eventType: 'Visite argumentée',
    city: 'Paris',
    clientName: 'Jon Snow',
    cipCode: '1234',
  },
  {
    id: 13,
    startDate: '2019-07-24T07:52:48.467Z',
    eventType: 'Visite argumentée',
    city: 'London',
    clientName: 'James Bond',
    cipCode: '1234',
  },
]

export const mockCalendarReportsData: IClientCalendarEvent[] = [
  {
    allDay: false,
    id: 12,
    start: '2019-07-24T07:52:48.467Z',
    title: 'Jon Snow (1234, Paris)',
    color: '#5630AA',
    end: '2019-07-24T07:52:48.467Z',
    extendedProps: {
      clientId: undefined,
    },
  },
  {
    allDay: false,
    id: 13,
    start: '2019-07-24T07:52:48.467Z',
    title: 'James Bond (1234, London)',
    color: '#5630AA',
    end: '2019-07-24T07:52:48.467Z',
    extendedProps: {
      clientId: undefined,
    },
  },
]

export const mockCalendarAppointmentsData: IClientCalendarEvent[] = [
  {
    allDay: false,
    end: '2019-07-24T10:52:48.467Z',
    id: 12,
    start: '2019-07-24T07:52:48.467Z',
    title: 'Jon Snow (1234, Paris)',
    color: '#004850',
  },
  {
    allDay: false,
    end: '2019-07-24T10:52:48.467Z',
    id: 13,
    start: '2019-07-24T07:52:48.467Z',
    title: 'James Bond (1234, London)',
    color: '#004850',
  },
]

export const mockAppointmentTypesData: IAppointmentTypesData = {
  total: 1,
  data: [
    {
      id: 1,
      eventType: 'Visite argumentée',
      isVisible: true,
    },
    {
      id: 2,
      eventType: 'Visite argumentée',
      isVisible: false,
    },
  ],
}

export const mockAppointmentTypesResponse: IResponseData<IArrayOptions[]> = {
  data: prepareAppointmentTypesOptions(mockAppointmentTypesData.data),
  status: STATUS.SUCCESS,
  message: '',
}

export const mockClientReportsData: IClientReportsData = {
  total: 1,
  data: [
    {
      id: 1,
      startDate: '2019-07-24T07:52:48.467Z',
      eventType: 'Raport de visite',
      contactPerson: 'Muriel Gretchen',
    },
  ],
}

export const mockAppointmentDefaultValues: IAppointmentDefault = {
  appointmentTypeId: 1,
  comment: '',
  userId: 3,
  prevLocation: '',
  offset: 0,
  currentPage: 1,
  id: 123,
  client: {
    id: 2,
    name: 'Catena',
    cipCode: 'cipCode123',
  },
  ...datesInitialState,
}

export const validAdminJWT: { authorization: string } = {
  authorization:
    'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTEyOTc2MzgsImV4cCI6MTYxMTMwMTIzOCwiaWQiOjMsInJvbGUiOiJST0xFX0FETUlOIn0.Ncnv533q-vfFhQVBDdxCF_b-XyuwQ5VoKXpsgDL-iF-SeOLZ_dq2ejp8cMNCBX_PsfdyJgZnZB0tMLLktir8SgHXPyJyfCXHbTQTu9Al3Gma8_f6QfVP1edLYGg97QEjTV9kYOx7vt_Zczooyg0_wt1FMjykY82Bcf3cNuNHrZs5AiP12bw49DXds31YOWLkig8UwP1opeNeFQZOjFp07rrm5G8wviz7DcnLqobF86Vj42y2iskFMdk9kgbdjRfulr9zj4PvXJgzSsshivgyOEtIkyrLfkf1ky1f4UZIS7lVbW1xcKgfxGPWERzgzCJY4wmBirLKIex9dudSVxJLg4Q_GW52IIy3Qvud8sK0QuTri5uA4qWHKqFU9GOpfZf0gcEi28Nv42m_ooie5GGBOo4-g1ku3YoLjUDFqylmtJdhgdvMCuBBrgWNF-Gom1YC-yUIFLa7ysUfTUeRx-PYUmuCdbVQOBfqVBuH3mJNJo4floiekGd9R37w00ozs5h1OTv9CnO2pw7n7QgVElB00pM3arMm_ePdE6IaqHTkkGpyPBOxsVU2oyvke2bh3HlmrP66LZGg0g0HtKhlwwhaahghKQq-r2n38hf-s8fXRjsHqKCdAa0JXl3dW_eZo3xUOASOUOTFVbbE9dqkvkdAtJ7gdlLJn1cCfZlDq5ht-mU',
}

export const validUserJWT: { authorization: string } = {
  authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTEyOTc3MzksImV4cCI6MTYxMTMwMTMzOSwiaWQiOjUsInJvbGUiOiJST0xFX1VTRVIifQ.vdxEGyPcjSDaCVRUPk-8RTMSTM0g08tKN3-cCDLPg8pI4oCE4UsTj-ONcOot0OBLPY_jKCJ6eBIhb6ibCk-NTG7n3T0uAuWgOYWkbarHsxcm5rWnFarBKmbkoza5zGQqZsG7c85t2xl2CZJhtmkHZwa_Nb3ZUJyh42g7QHy21N9A2cCYKFjnXOEqjJSZyEtJp_HJ3HQ_W8JQVO3c0yxRRclC4Zlvdv3ZFE1i3dkCxydFRA5JxU8HXalIHXK4j0SOTAZ1obyDqkwzMVh1D3JhA0Sy-atqCO0fXv4hzw_UvMDyCi1Ex-nATpI0ImIxdl6teOFBh_kPSacLdZeYwGaItwjlCD_WNLyHjErTteHemRvA9iRj6WU_fhs5ZHgZ9gtMKc4TgPzUmKZKcJn7tFM5wwm2txUMle9RMXcIspQopV1CFNORJYgAHnqKxdcIgKf_DksboJaPEulSIJq5Pens11KWzgvdSh4fVZut_kUQENJXF1ekyhMD1iG5SEH3b8_W-xjIV9cwPlC-KrMPFYA-2TmOeleoLnvxNQ0m_5KnYIsTyOXYCXslRI466vcyk2x9hz_Jbs5LFPHAK1R0aOdJW7-W1F1R0FSXLsNpkV_IqGWHM8ChV6a16IVM_cvQMvbe-5GI60-zFV7HKPKHaaHCJTBKARNrVGfsRjcoj68ntZ4`,
}

export const mockUsersList: IUsersResponse = {
  data: [
    {
      id: 2,
      firstName: 'Jane',
      lastName: 'Doe',
      username: 'doe',
    },
    {
      id: 3,
      firstName: 'James',
      lastName: 'Bond',
      username: 'bond',
    },
    {
      id: 4,
      firstName: 'Jon',
      lastName: 'Andrews',
      username: 'jon',
    },
    {
      id: 1,
      firstName: 'Daenerys',
      lastName: 'Targaryen',
      username: 'targaryen',
    },
  ],
}

export const mockClientDetailsItemData: TClientDetailsItem = {
  id: 123,
  cipCode: '9999999',
  name: 'Pharmacie Test',
  address1: 'Rue des long pres',
  address2: '',
  zipCode: '92100',
  city: 'BOULOGNE BILLANCOURT',
  phoneNumber: '0170956400',
  gpsLongitude: 46.2276,
  gpsLatitude: 2.2137,
  lastVisitReport: {},
  nextAppointmentDate: '',
  withAppointment: false,
  withVisitReport: false,
}

export const mockClientAppointmentsResponseData: IClientAppointmentsResponseData = {
  total: 1,
  status: STATUS.SUCCESS,
  clientAppointmentsData: [
    {
      id: 1,
      startDate: '2019-07-24T07:52:48.467Z',
      eventType: 'Raport de visite',
      contactPerson: 'Muriel Gretchen',
    },
  ],
}

export const mockClientReportsResponseData: IClientReportsResponseData = {
  total: 1,
  status: STATUS.SUCCESS,
  clientReportsData: [
    {
      id: 1,
      startDate: '2019-07-24T07:52:48.467Z',
      eventType: 'Raport de visite',
      contactPerson: 'Anderson Silva',
    },
  ],
}

export const mockSelectClientDetails: ISelectClientDetails = {
  id: 420,
  cipCode: '420',
  name: 'John Blazer',
}

export const mockOrderHistoryResponse: IOrderHistory[] = [
  {
    date: '2020-12-21T07:52:48.467Z',
    deliveryDate: '2020-12-23T07:52:48.467Z',
    orderNumber: 123455,
    source: 'KAM',
    amount: 55.65,
  },
  {
    date: '2020-12-21T07:52:48.467Z',
    deliveryDate: '2020-12-23T07:52:48.467Z',
    orderNumber: 123455,
    source: 'KAM',
    amount: 55.65,
  },
  {
    date: '2020-12-21T07:52:48.467Z',
    deliveryDate: '2020-12-23T07:52:48.467Z',
    orderNumber: 123455,
    source: 'KAM',
    amount: 0,
  },
]

export const mockOrderHistoryTableData: IOrderHistory[] = [
  {
    date: '21.12.2020',
    deliveryDate: '23.12.2020',
    orderNumber: 123455,
    source: 'KAM',
    amount: 55.65,
  },
  {
    date: '21.12.2020',
    deliveryDate: '23.12.2020',
    orderNumber: 123455,
    source: 'KAM',
    amount: 55.65,
  },
  {
    date: '21.12.2020',
    deliveryDate: '23.12.2020',
    orderNumber: 123455,
    source: 'KAM',
    amount: 0,
  },
]

export const mockEngagemenstHistoryResponse: IEngagementHistory[] = [
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '2020-11-12T14:25:00.467Z',
    discountRate: 25,
    details: 1000,
  },
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '2020-11-12T14:25:00.467Z',
    discountRate: 25,
    details: 1000,
  },
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '2020-11-12T14:25:00.467Z',
    discountRate: 25,
    details: 1000,
  },
]

export const mockEngagemenstHistoryTableData: IEngagementHistory[] = [
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '12.11.2020 14:25',
    discountRate: '25%',
    details: 1000,
  },
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '12.11.2020 14:25',
    discountRate: '25%',
    details: 1000,
  },
  {
    contractLabel: '2020 - Frontline direct',
    category: 'Eqvalan',
    date: '12.11.2020 14:25',
    discountRate: '25%',
    details: 1000,
  },
]

export const mockCurrentDayEvents: IAppointmentList[] = [
  {
    id: 12,
    startDate: '2021-02-15T10:00:00.467Z',
    endDate: '2021-02-15T11:00:00.467Z',
    contactPerson: 'Muriel Gretchen',
    eventType: 'Visite argumentée',
    clientName: 'Jon Snow',
    cipCode: '1234567',
    city: 'Paris',
    address1: 'Rue de Lavande no10',
    zipCode: '123456',
  },
  {
    id: 13,
    startDate: '2021-02-15T11:00:00.467Z',
    endDate: '2021-02-15T12:00:00.467Z',
    contactPerson: 'Tony Stark',
    eventType: 'Visite argumentée',
    clientName: 'James Bond',
    cipCode: '7654321',
    city: 'London',
    address1: 'Road to Heaven no29',
    zipCode: '123456',
  },
]

export const mockContractTypes: IContractData[] = [
  {
    id: 1,
    label: 'Direct Frontline',
    nrOfContracts: 2,
    docType: '',
  },
  {
    id: 2,
    label: 'Direct Chevaux',
    nrOfContracts: 10,
    docType: '',
  },
  {
    id: 3,
    label: 'Distributeur Frontline',
    nrOfContracts: 1,
    docType: '',
  },
  {
    id: 4,
    label: 'Distributeur Chevaux',
    nrOfContracts: 6,
    docType: '',
  },
  {
    id: 5,
    label: 'Groupement Direct Frontline',
    nrOfContracts: 2,
    docType: '',
  },
  {
    id: 6,
    label: 'Groupement Distributeur Frontline',
    nrOfContracts: 8,
    docType: '',
  },
  {
    id: 7,
    label: 'ELSIE Direct Frontline',
    nrOfContracts: 0,
    docType: '',
  },
  {
    id: 8,
    label: 'Direct Frontline nouveau client',
    nrOfContracts: 15,
    docType: '',
  },
  {
    id: 9,
    label: 'Distributeur Frontline nouveau client',
    nrOfContracts: 11,
    docType: '',
  },
  {
    id: 10,
    label: 'Groupement Direct Frontline nouveau client',
    nrOfContracts: 23,
    docType: '',
  },
  {
    id: 11,
    label: 'Groupement Distributeur Frontline nouveau client',
    nrOfContracts: 2,
    docType: '',
  },
]

export const mockNewVisitReportFormInitialState: IVisitReportForm = {
  userId: '',
  contactPersonId: '',
  visitDate: '',
  duration: null,
  contactTypeId: null,
  marche: [],
  suiviLitige: false,
  suiviInformation: false,
  suiviReassort: false,
  suiviModification: false,
  suiviFacture: false,
  suiviLivraison: false,
  suiviProblemeService: false,
  suiviProblemeCommande: false,
  comment: '',
  productNeo: false,
  productNeoPhoto: '',
  productNeoComment: '',
  productVision: false,
  productVisionPhoto: '',
  productVisionComment: '',
  productLibre: false,
  productLibrePhoto: '',
  productLibreComment: '',
  servicesQuizFormation: false,
  servicesQuizNoPersons: '',
  servicesQuizPhoto: '',
  servicesQuizComment: '',
  servicesCoachFormation: false,
  servicesCoachNoPersons: '',
  servicesCoachPhoto: '',
  servicesCoachComment: '',
  eventsEchSensor: false,
  eventsGroupPatient: false,
  eventsGroupPatientNoPersons: '',
  eventsGroupPatientNoDownloads: '',
  eventsGroupPatientNoSubscribed: '',
  eventsGroupPatientSigned: false,
  eventsGroupPatientPhoto: '',
  eventsGroupPatientComment: '',
  eventsEasyCollect: false,
  eventsEasyCollectPhoto: '',
  eventsEasyCollectComment: '',
  eventsSsm: false,
  eventsSsmPhoto: '',
  eventsSsmComment: '',
  eventsGroupConvergence: false,
  eventsGroupConvergencePhoto: '',
  eventsGroupConvergenceComment: '',
  selloutReassort: false,
  selloutMerch: false,
  selloutMerchPhoto: '',
  selloutEngagement: false,
  selloutEngagementVisionNo: '',
  selloutEngagementNeoNo: '',
  selloutEngagementQacNoPersons: '',
  selloutFsl: false,
  selloutFslNo: '',
  selloutFslIdentificationNoPersons: '',
  docsAvenant: false,
  docsRpConv: false,
  docsRpConvPerson1: '',
  docsRpConvPerson1Func: '',
  docsRpConvPerson2: '',
  docsRpConvPerson2Func: '',
  docsGroupePatient: false,
  docsEasyCollect: false,
  docsEasyCollectPhoto: '',
  docsSsm: false,
  docsBgm: false,
  docsFsl: false,
  docsOther: false,
  docsOtherComment: '',
  docsOtherPhoto: '',
  version: VISIT_REPORT_CURRENT_VERSION,
}

const lastYear = new Date().getFullYear() - 1
const currentYear = new Date().getFullYear()

export const mockSummaryRecapHistoryResponse: IRecapSummary[] = [
  {
    year: lastYear,
    periods: [
      {
        name: 'TOTAL1',
        quantity: 1,
        value: 2.0,
      },
    ],
  },
  {
    year: currentYear,
    periods: [
      {
        name: 'TOTAL2',
        quantity: 1,
        value: 2.0,
      },
    ],
  },
]

export const mockProcessedSummaryData = [
  {
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
  },
  {
    currentYear: {
      TOTAL2: {
        quantity: 1,
        value: 2,
      },
    },
  },
]

export const mockProductsRecapHistoryResponse: IRecapProducts[] = [
  {
    name: 'Product1',
    summary: [
      {
        year: lastYear,
        periods: [
          {
            name: 'TOTAL1',
            quantity: 1,
            value: 2.0,
          },
        ],
      },
      {
        year: currentYear,
        periods: [
          {
            name: 'TOTAL2',
            quantity: 1,
            value: 2.0,
          },
        ],
      },
    ],
  },
]

export const mockProcessedProductsData = [
  {
    currentYear: {
      TOTAL2: {
        quantity: 1,
        value: 2,
      },
    },
    name: 'Product1',
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
    type: 'product',
  },
]

export const mockAnnualHistoryResponse: IRecapHistory[] = [
  {
    name: 'ProductLine1',
    summary: [
      {
        year: lastYear,
        periods: [
          {
            name: 'TOTAL1',
            quantity: 1,
            value: 2.0,
          },
        ],
      },
      {
        year: currentYear,
        periods: [
          {
            name: 'TOTAL2',
            quantity: 1,
            value: 2.0,
          },
        ],
      },
    ],
    products: [
      {
        name: 'Product1',
        summary: [
          {
            year: lastYear,
            periods: [
              {
                name: 'TOTAL1',
                quantity: 1,
                value: 2.0,
              },
            ],
          },
          {
            year: currentYear,
            periods: [
              {
                name: 'TOTAL2',
                quantity: 1,
                value: 2.0,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'ProductLine15',
    summary: [
      {
        year: 2020,
        periods: [
          {
            name: 'TOTAL1',
            quantity: 1,
            value: 2.0,
          },
        ],
      },
    ],
    products: [
      {
        name: 'Product1',
        summary: [
          {
            year: 2020,
            periods: [
              {
                name: 'TOTAL1',
                quantity: 1,
                value: 2.0,
              },
            ],
          },
        ],
      },
    ],
  },
]

export const mockProcessedAnnualHistoryReponse: IHistoryTableRow[] = [
  {
    currentYear: {
      TOTAL2: {
        quantity: 1,
        value: 2,
      },
    },
    name: 'ProductLine1',
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
    type: 'summary',
  },
  {
    currentYear: {
      TOTAL2: {
        quantity: 1,
        value: 2,
      },
    },
    name: 'Product1',
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
    type: 'product',
  },
  {
    name: 'ProductLine15',
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
    type: 'summary',
  },
  {
    name: 'Product1',
    previousYear: {
      TOTAL1: {
        quantity: 1,
        value: 2,
      },
    },
    type: 'product',
  },
]

export const mockGetContractTypeForm: any = {
  formId: 1,
  label: 'Direct Frontline',
  client: {
    id: 123,
    contactPerson: 'Muriel Gretchen',
    address1: 'Rue des long pres',
    address2: null,
    zipCode: '92100',
    city: 'BOULOGNE BILLANCOURT',
  },
  fields: [
    {
      id: 1,
      label: 'Furexel, Furexel combi',
      order: 1,
      mandatory: true,
      options: [
        {
          name: '10% à partir de 150€',
          value: 1,
        },
        {
          name: '50% à partir de 1500€',
          value: 2,
        },
        {
          name: 'Non',
          value: 3,
        },
      ],
    },
    {
      id: 2,
      label: 'Eqvalan, Eqvalan duo',
      order: 1,
      mandatory: true,
      options: [
        {
          name: '5% à partir de 550€',
          value: 1,
        },
        {
          name: '12% à partir de 3000€',
          value: 2,
        },
        {
          name: 'Non',
          value: 3,
        },
      ],
    },
    {
      id: 3,
      label: 'Gastrogard',
      order: 1,
      mandatory: true,
      options: [
        {
          name: '5% à partir de 650€',
          value: 1,
        },
        {
          name: 'Non',
          value: 2,
        },
      ],
    },
  ],
}

export const mockReclamationFormValues: IReclamationFormFields = {
  age: '',
  agreementContactInfo: 'Non',
  agreementExtraDetails: '' || null,
  city: '',
  description: '',
  email: '',
  expirationDate: '',
  fax: '',
  fullName: '',
  gender: '' || null,
  incidentDate: '',
  initials: '',
  laboratory: '',
  lot: '',
  notifier: '' || null,
  officeName: '',
  other: '',
  patient: '',
  phone: '',
  postalCode: '',
  product: '',
  productCipCode: '',
  productConcerned: '',
  reclamationAddress: '',
  specialtyAvailable: '' || null,
  type: '' || null,
  signature: '',
}

export const mockOfflineSynchroData: IOfflineSynchroData[] = [
  {
    creationDate: '23.12.2020',
    fileName: '/dashboard',
    status: '200',
  },
  {
    creationDate: '23.12.2020',
    fileName: '/dashboard',
    status: '200',
  },
]

const longText =
  'Lorem ipsum dolor sit amet, consectetueradipiscingelitAeneancommodligulaegetdolorAeneamassaCumsociisnatoquepenatibusetmagnisdisparturientmontesnasceturridiculusmusDonec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Fusce vulputate eleifend sapien. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Nullam accumsan lorem in dui. Cras ultricies mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In ac dui quis mi consectetuer lacinia. Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Praesent adipiscing. Phasellus ullamcorper ipsum rutrum nunc. Nunc nonummy metus. Vestib'
const longTextWithHtml =
  '<ol>\n' +
  '<li><span style="color: rgb(0,72,80);background-color: rgb(255,255,255);font-size: 16px;font-family: Roboto, Arial, sans-serif;">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras <strong>dapibus</strong>. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ip<em>sum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Fusce vulputate eleifend sapien. Vestibulum purus quam, sceleri</em>sque ut, </span></li>\n' +
  '<li><span style="color: rgb(0,72,80);background-color: rgb(255,255,255);font-size: 16px;font-family: Roboto, Arial, sans-serif;">mollis sed, nonummy id, metus. Nullam accumsan lorem in dui. Cras ultricies mi eu turpis hendrerit fringilla. Vestibulum ante ipsum primis in faucibus orci </span></li>\n' +
  '</ol>\n'

export const mockLastVisitReportInformation: IVisitReportInformation = {
  numberOfAppels: 2,
  numberOfVisite: 3,
  rdvDate: '2022-03-30T15:54:43+00:00',
  kamComment: longTextWithHtml,
  dcrComment: longTextWithHtml,
  lastVisiteDate: '2022-03-29T15:54:43+00:00',
  lastVisiteType: 'Visite à distance',
  lastVisiteComment: longText,
  subscribeComptoir: true,
  subscribeCoach: false,
  productNeoComment:
    '<p style="text-align:start;"><span style="color: rgb(0,72,80);background-color: rgb(255,255,255);font-size: 16px;font-family: Roboto, Arial, sans-serif;">Freestyle Optium Neo comment</span></p>\n',
  productVisionComment:
    '<p>Freestyle Papillon Vision comment</p>\n<p style="margin-left:0px;"><br></p>\n',
  productLibreComment: '<p>Freestyle Libre comment</p>\n',
  servicesQuizComment:
    '<p style="text-align:start;"><span style="color: rgb(0,72,80);background-color: transparent;font-size: 16px;font-family: Roboto, Arial, sans-serif;">Questionnaire au comptoir comment</span></p>\n<p style="text-align:start;"></p>\n',
  servicesCoachComment: '<p>Coach comment</p>',
  eventsGroupPatientComment: '<p>Groupe Patient comment</p>\n<p></p>',
  eventsEasyCollectComment: '<p>Easy Collect comment</p>',
  eventsSsmComment: '<p>SSM comment</p>',
  eventsGroupConvergenceComment: '<p>Groupe Convergence comment</p>',
}

export const mockVisitReportContactTypes = {
  data: [
    {
      id: 1,
      name: 'Visit',
    },
    {
      id: 2,
      name: 'Visite á Distance',
    },
    {
      id: 3,
      name: 'Appel bref',
    },
  ],
}

export const mockVisitReportContactPersons = {
  data: [
    {
      id: 1,
      firstName: 'Levente',
      lastName: 'Kibedi',
    },
  ],
}

export const mockContractDetailsFields: IContractDetailsFields[] = [
  {
    id: 11,
    label: 'Recevant',
    name: 'recevant',
    placeholder: '',
    order: 1,
    mandatory: true,
    type: 'groupedInput',
    items: [
      {
        id: 123,
        name: 'recNom',
        label: '',
        placeholder: 'Nom',
        type: 'text',
        order: 1,
      },
      {
        id: 124,
        name: 'recPrenom',
        label: '',
        placeholder: 'Prenom',
        type: 'text',
        order: 1,
      },
    ],
  },
  {
    id: 12,
    name: 'lot',
    label: 'Lot',
    order: 2,
    mandatory: true,
    type: 'text',
    placeholder: '',
  },
  {
    id: 13,
    name: 'sn',
    label: 'SN',
    order: 2,
    mandatory: true,
    type: 'text',
    placeholder: '',
  },
  {
    id: 12,
    name: 'lacteur_papillon_vision',
    label: 'Lecteur FreeStyle PAPILLON Vision',
    order: 2,
    mandatory: true,
    type: 'range',
    valueStart: 0,
    valueEnd: 20,
    placeholder: '',
  },
  {
    id: 13,
    name: 'lacteur_optium_neo',
    label: 'Lecteur FreeStyle Optium Neo',
    order: 3,
    mandatory: true,
    type: 'range',
    valueStart: 0,
    valueEnd: 20,
    placeholder: '',
  },
  {
    id: 14,
    name: 'electrodes_papillon_easy',
    label: 'Electrodes FreeStyle PAPILLON Easy (25)',
    order: 4,
    mandatory: true,
    type: 'range',
    valueStart: 0,
    valueEnd: 20,
    placeholder: '',
  },
  {
    id: 15,
    name: 'electrodes_optium',
    label: 'Electrodes FreeStyle Optium (25)',
    order: 5,
    mandatory: true,
    type: 'range',
    valueStart: 0,
    valueEnd: 20,
    placeholder: '',
  },
  {
    id: 16,
    name: 'electrodes_optium_bketone',
    label: 'Electrodes FreeStyle Optium b-Ketone (10)',
    order: 6,
    mandatory: true,
    type: 'range',
    valueStart: 0,
    valueEnd: 20,
    placeholder: '',
  },
  {
    id: 17,
    name: 'electrodes_optium_hbketone',
    label: 'Electrodes FreeStyle Optium H b-Ketone (50)',
    order: 7,
    mandatory: true,
    type: 'range',
    valueStart: 0,
    valueEnd: 20,
    placeholder: '',
  },
]

const mockVisitReportImage = {
  key: null,
  content: null,
}

export const mockViewVisitReportData: IVisitReport = {
  comment: '<p>new report</p>\n',
  contactPerson: 'Person Name',
  contactType: 'Visite á Distance',
  date: '2022-05-02',
  docsAvenant: true,
  docsBgm: true,
  docsEasyCollect: true,
  docsEasyCollectPhoto: mockVisitReportImage,
  docsFsl: true,
  docsGroupePatient: true,
  docsOther: true,
  docsOtherComment: 'some comment',
  docsOtherPhoto: mockVisitReportImage,
  docsRpConv: true,
  docsRpConvPerson1: 'Nom1',
  docsRpConvPerson1Func: 'Func1',
  docsRpConvPerson2: 'Nom2',
  docsRpConvPerson2Func: 'Func2',
  docsSsm: true,
  duration: '60',
  eventsEchSensor: true,
  eventsEasyCollect: true,
  eventsEasyCollectPhoto: mockVisitReportImage,
  eventsEasyCollectComment: longTextWithHtml,
  eventsGroupConvergence: true,
  eventsGroupConvergencePhoto: mockVisitReportImage,
  eventsGroupConvergenceComment: '',
  eventsGroupPatient: true,
  eventsGroupPatientNoDownloads: 50,
  eventsGroupPatientNoPersons: 20,
  eventsGroupPatientNoSubscribed: 40,
  eventsGroupPatientPhoto: mockVisitReportImage,
  eventsGroupPatientSigned: true,
  eventsGroupPatientComment: 'some comment',
  eventsSsm: true,
  eventsSsmPhoto: mockVisitReportImage,
  eventsSsmComment: 'some comment',
  id: 140,
  marche: 'En attente',
  productLibre: true,
  productLibrePhoto: mockVisitReportImage,
  productLibreComment: 'some comment',
  productNeo: true,
  productNeoPhoto: mockVisitReportImage,
  productNeoComment: 'some comment',
  productVision: true,
  productVisionPhoto: mockVisitReportImage,
  productVisionComment: 'some comment',
  selloutEngagement: true,
  selloutEngagementNeoNo: 30,
  selloutEngagementVisionNo: 25,
  selloutEngagementFslNo: 25,
  selloutEngagementQacNoPersons: 25,
  selloutIdentificationQac: true,
  selloutIdentificationQacNoPersons: 29,
  selloutIdentificationFsl: true,
  selloutIdentificationFslNoPersons: 29,
  selloutFsl: true,
  selloutFslNo: 25,
  selloutFslIdentificationNoPersons: 25,
  selloutMerch: true,
  selloutMerchPhoto: mockVisitReportImage,
  selloutReassort: true,
  servicesCoachFormation: true,
  servicesCoachNoPersons: 95,
  servicesCoachPhoto: mockVisitReportImage,
  servicesCoachComment: 'some comment',
  servicesQuizFormation: true,
  servicesQuizNoPersons: 20,
  servicesQuizPhoto: mockVisitReportImage,
  servicesQuizComment: 'some comment',
  suiviFacture: null,
  suiviInformation: null,
  suiviLitige: null,
  suiviLivraison: null,
  suiviModification: null,
  suiviProblemeCommande: null,
  suiviProblemeService: null,
  suiviReassort: null,
  version: VISIT_REPORT_CURRENT_VERSION,
}

export const mockContractHistoryData: IContractHistoryData = {
  currentYear: {
    year: 2022,
    listContractTypes: [
      {
        id: 24,
        label: 'Direct Frontline',
        nrOfContracts: 97,
        docType: '',
      },
      {
        id: 25,
        label: 'Direct Chevaux',
        nrOfContracts: 33,
        docType: '',
      },
      {
        id: 26,
        label: 'Distributeur Frontline',
        nrOfContracts: 8,
        docType: '',
      },
      {
        id: 27,
        label: 'Distributeur Chevaux',
        nrOfContracts: 15,
        docType: '',
      },
      {
        id: 28,
        label: 'Groupement Direct Frontline',
        nrOfContracts: 6,
        docType: '',
      },
      {
        id: 29,
        label: 'Groupement Distributeur Frontline',
        nrOfContracts: 6,
        docType: '',
      },
      {
        id: 30,
        label: 'ELSIE Direct Frontline',
        nrOfContracts: 2,
        docType: '',
      },
      {
        id: 31,
        label: 'Direct Frontline nouveau client',
        nrOfContracts: 3,
        docType: '',
      },
      {
        id: 32,
        label: 'Distributeur Frontline nouveau client',
        nrOfContracts: 2,
        docType: '',
      },
      {
        id: 35,
        label: 'ELSIE Distributeur Frontline',
        nrOfContracts: 3,
        docType: '',
      },
    ],
  },
  history: [
    {
      year: 2021,
      listContractTypes: [
        {
          id: 24,
          label: 'Direct Frontline',
          nrOfContracts: 97,
          docType: '',
        },
        {
          id: 25,
          label: 'Direct Chevaux',
          nrOfContracts: 33,
          docType: '',
        },
        {
          id: 26,
          label: 'Distributeur Frontline',
          nrOfContracts: 8,
          docType: '',
        },
      ],
    },
    {
      year: 2020,
      listContractTypes: [
        {
          id: 29,
          label: 'Groupement Distributeur Frontline',
          nrOfContracts: 6,
          docType: '',
        },
        {
          id: 30,
          label: 'ELSIE Direct Frontline',
          nrOfContracts: 2,
          docType: '',
        },
        {
          id: 31,
          label: 'Direct Frontline nouveau client',
          nrOfContracts: 3,
          docType: '',
        },
      ],
    },
    {
      year: 2019,
      listContractTypes: [
        {
          id: 32,
          label: 'Distributeur Frontline nouveau client',
          nrOfContracts: 2,
          docType: '',
        },
        {
          id: 35,
          label: 'ELSIE Distributeur Frontline',
          nrOfContracts: 3,
          docType: '',
        },
      ],
    },
  ],
}

export const mockContractHistoryDataEmptyHistory: IContractHistoryData = {
  currentYear: {
    year: 2022,
    listContractTypes: [
      {
        id: 24,
        label: 'Direct Frontline',
        nrOfContracts: 97,
        docType: '',
      },
      {
        id: 25,
        label: 'Direct Chevaux',
        nrOfContracts: 33,
        docType: '',
      },
      {
        id: 26,
        label: 'Distributeur Frontline',
        nrOfContracts: 8,
        docType: '',
      },
      {
        id: 27,
        label: 'Distributeur Chevaux',
        nrOfContracts: 15,
        docType: '',
      },
      {
        id: 28,
        label: 'Groupement Direct Frontline',
        nrOfContracts: 6,
        docType: '',
      },
      {
        id: 29,
        label: 'Groupement Distributeur Frontline',
        nrOfContracts: 6,
        docType: '',
      },
      {
        id: 30,
        label: 'ELSIE Direct Frontline',
        nrOfContracts: 2,
        docType: '',
      },
      {
        id: 31,
        label: 'Direct Frontline nouveau client',
        nrOfContracts: 3,
        docType: '',
      },
      {
        id: 32,
        label: 'Distributeur Frontline nouveau client',
        nrOfContracts: 2,
        docType: '',
      },
      {
        id: 35,
        label: 'ELSIE Distributeur Frontline',
        nrOfContracts: 3,
        docType: '',
      },
    ],
  },
  history: [],
}

export const mockVisitReport: IVisitReport = {
  id: 123,
  date: '2022-07-13',
  duration: '60',
  contactPerson: '',
  contactType: 'Visite',
  comment: '<p>dadwqdqwdqw</p>\n',
  marche: '',
  suiviLitige: null,
  suiviInformation: null,
  suiviReassort: null,
  suiviModification: null,
  suiviFacture: null,
  suiviLivraison: null,
  suiviProblemeService: null,
  suiviProblemeCommande: null,
  productNeo: false,
  productNeoPhoto: mockVisitReportImage,
  productNeoComment: '',
  productVision: true,
  productVisionPhoto: mockVisitReportImage,
  productVisionComment: '<p>dsdqdqd</p>\n',
  productLibre: false,
  productLibrePhoto: mockVisitReportImage,
  productLibreComment: '',
  servicesQuizFormation: false,
  servicesQuizNoPersons: 0,
  servicesQuizPhoto: mockVisitReportImage,
  servicesQuizComment: '',
  servicesCoachFormation: false,
  servicesCoachNoPersons: 0,
  servicesCoachPhoto: mockVisitReportImage,
  servicesCoachComment: '',
  eventsGroupPatient: false,
  eventsGroupPatientNoPersons: 0,
  eventsGroupPatientNoDownloads: 0,
  eventsGroupPatientNoSubscribed: 0,
  eventsGroupPatientSigned: false,
  eventsGroupPatientPhoto: mockVisitReportImage,
  eventsGroupPatientComment: '',
  eventsEasyCollect: false,
  eventsEasyCollectPhoto: mockVisitReportImage,
  eventsEasyCollectComment: '',
  eventsSsm: false,
  eventsSsmPhoto: mockVisitReportImage,
  eventsSsmComment: '',
  eventsGroupConvergence: false,
  eventsGroupConvergencePhoto: mockVisitReportImage,
  eventsGroupConvergenceComment: '',
  eventsEchSensor: false,
  selloutReassort: false,
  selloutMerch: false,
  selloutMerchPhoto: mockVisitReportImage,
  selloutEngagement: false,
  selloutIdentificationQac: false,
  selloutIdentificationQacNoPersons: 0,
  selloutIdentificationFsl: false,
  selloutIdentificationFslNoPersons: 0,
  selloutEngagementVisionNo: 0,
  selloutEngagementNeoNo: 0,
  selloutEngagementFslNo: 0,
  selloutEngagementQacNoPersons: 0,
  selloutFsl: false,
  selloutFslNo: 0,
  selloutFslIdentificationNoPersons: 0,
  docsAvenant: false,
  docsRpConv: false,
  docsRpConvPerson1: '',
  docsRpConvPerson1Func: '',
  docsRpConvPerson2: '',
  docsRpConvPerson2Func: '',
  docsGroupePatient: false,
  docsEasyCollect: false,
  docsEasyCollectPhoto: mockVisitReportImage,
  docsSsm: false,
  docsBgm: false,
  docsFsl: false,
  docsOther: false,
  docsOtherComment: '',
  docsOtherPhoto: mockVisitReportImage,
  version: 'vTesting',
}

export const contractDetailMock: ICustomContract = {
  cipCode: '2001439',
  contractDate: '2020-11-08',
  clientName: 'Richter Tudor 5001 Test Pharmacy',
  contractLabel: 'Abbot 2022',
  creationDate: '2019-01-20T00:00:00Z',
  dcrEmail: 'galien-ne-pas-repondre@webhelpmedica.com',
  id: 47,
  isDownloadModalOpen: false,
  isHistoryPage: true,
  kamEmail: 'galien-ne-pas-repondre@webhelpmedica.com',
  setControlDownload: () => {},
  downloadFormState: DATA_INITIAL_STATE,
  setDownloadFormState: () => {},
  setSendEmailState: () => {},
  setControlDelete: () => {},
  setAvailableEmails: () => {},
  deleted: false,
  sectorCode: '123',
  isPdfGenerated: true,
  contractType: 'Contract Type',
}

export const contractDetailMockNoPdf: ICustomContract = {
  cipCode: '2001439',
  contractDate: '2020-11-08',
  clientName: 'Richter Tudor 5001 Test Pharmacy',
  contractLabel: 'Abbot 2022',
  creationDate: '2019-01-20T00:00:00Z',
  dcrEmail: 'galien-ne-pas-repondre@webhelpmedica.com',
  id: 47,
  isDownloadModalOpen: false,
  isHistoryPage: true,
  kamEmail: 'galien-ne-pas-repondre@webhelpmedica.com',
  setControlDownload: () => {},
  downloadFormState: DATA_INITIAL_STATE,
  setDownloadFormState: () => {},
  setSendEmailState: () => {},
  setControlDelete: () => {},
  setAvailableEmails: () => {},
  deleted: false,
  sectorCode: '123',
  isPdfGenerated: false,
  contractType: 'Contract Type',
}

export const mockMarqueData: ISelectOption[] = [
  { value: '1', label: 'Marque 1' },
  { value: '2', label: 'Marque 2' },
  { value: '3', label: 'Marque 3' },
]

export const mockMarque: IResponseData<any> = {
  data: mockMarqueData,
  status: 'success',
  message: '',
}

export const turnoverMockData: ITurnoverData = {
  currentYear: [
    {
      month: 1,
      turnover: 20,
    },
    {
      month: 2,
      turnover: 35,
    },
    {
      month: 3,
      turnover: 15,
    },
    {
      month: 4,
      turnover: 40,
    },
    {
      month: 5,
      turnover: 21,
    },
    {
      month: 6,
      turnover: 18,
    },
    {
      month: 7,
      turnover: 20,
    },
    {
      month: 8,
      turnover: 60,
    },
    {
      month: 9,
      turnover: 45,
    },
    {
      month: 10,
      turnover: 23,
    },
    {
      month: 11,
      turnover: 50,
    },
    {
      month: 12,
      turnover: 20,
    },
  ],
  lastYear: [
    {
      month: 1,
      turnover: 20,
    },
    {
      month: 2,
      turnover: 15,
    },
    {
      month: 3,
      turnover: 25,
    },
    {
      month: 4,
      turnover: 12,
    },
    {
      month: 5,
      turnover: 45,
    },
    {
      month: 6,
      turnover: 65,
    },
    {
      month: 7,
      turnover: 85,
    },
    {
      month: 8,
      turnover: 40,
    },
    {
      month: 9,
      turnover: 25,
    },
    {
      month: 10,
      turnover: 16,
    },
    {
      month: 11,
      turnover: 35,
    },
    {
      month: 12,
      turnover: 37,
    },
  ],
}

export const usersResponseMockData: IResponseData<any> = {
  data: mockUsersList,
  status: 'success',
  message: '',
}

export const filtersMock: IDashboardCaFilters = {
  marque: [
    {
      value: '1',
      label: 'Marque 1',
    },
    {
      value: '2',
      label: 'Marque 2',
    },
    {
      value: '3',
      label: 'Marque 3',
    },
  ],
  targetType2Ids: [
    {
      value: '1',
      label: 'TC2-Lib',
    },
    {
      value: '4',
      label: 'Type1',
    },
    {
      value: '5',
      label: 'Type2',
    },
    {
      value: '6',
      label: 'Type3',
    },
  ],
  groupIds: [
    {
      value: '1',
      label: 'hgj',
    },
    {
      value: '2',
      label: 'Label2',
    },
    {
      value: '3',
      label: 'Label3',
    },
  ],
}

export const filtersMockSelection: IDashboardCaFilters = {
  marque: [
    {
      value: '1',
      label: 'Marque 1',
    },
  ],
  targetType2Ids: [
    {
      value: '1',
      label: 'TC2-Lib',
    },
  ],
  groupIds: [
    {
      value: '2',
      label: 'Label2',
    },
  ],
}

export const mockTargetType: IResponseData<any> = {
  data: filtersMock.targetType2Ids,
  status: 'success',
  message: '',
}

export const mockGroup: IResponseData<any> = {
  data: filtersMock.groupIds,
  status: 'success',
  message: '',
}

export const mockVisitsCoverageData: IVisitsCoverageData = {
  visitedPharmacies: 10,
  unvisitedPharmacies: 5,
  visits: 50,
  visitsFrequency: 5,
}

export const mockVisitsCoverageDataEmpty: IVisitsCoverageData = {
  visitedPharmacies: 0,
  unvisitedPharmacies: 0,
  visits: 0,
  visitsFrequency: 0,
}

export const mockVisitsCoverageDataPerType: IVisitsCoverageData = {
  visitedPharmacies: 25,
  unvisitedPharmacies: 10,
  visits: 100,
  visitsFrequency: 4,
}

export const mockFormState: IHandledError = {
  status: STATUS.SUCCESS,
  message: '',
  messageCode: '',
}

export const mockSelectedUserName = 'JON Smith'

export const mockSelectedUser = {
  id: '2',
  name: mockSelectedUserName,
}

export const mockActivityPerMonth = (month: number): IActivity => {
  return {
    month,
    clientsActivity: {
      visits: 8,
      appointments: 10,
      appointmentsWithVisitReportPercentage: 80,
      appointmentsWithoutVisitReportPercentage: 20,
      visitsPercentage: 57,
      opportunityVisitsPercentage: 33,
    },
    prospectsActivity: {
      visits: 6,
      appointments: 10,
      appointmentsWithVisitReportPercentage: 60,
      appointmentsWithoutVisitReportPercentage: 40,
      visitsPercentage: 42,
      opportunityVisitsPercentage: 48,
    },
  }
}

const activity = getArraySequence(12).reduce((acc, currentValue) => {
  acc.push(mockActivityPerMonth(currentValue))

  return acc
}, [] as IActivity[])

export const mockDashboardActivityReports: IUserActivity = {
  visitReportsSummary: {
    currentMonth: 2,
    currentTrimester: 2,
    currentYear: 256,
  },
  activity,
}

export const mockAnyData: ISelectOption[] = []

export const mockResponseAnyData: IResponseData<any> = {
  data: mockAnyData,
  status: 'success',
  message: '',
}

export const mockDataStatusPendingByAction = {
  [ACTION_NOUVEAU_RENDEZ_VOUS]: false,
  [ACTION_NOUVEAU_RAPPORT]: false,
  [ACTION_HISTORIQUE]: false,
  [ACTION_FORMULAIRES]: true,
}
