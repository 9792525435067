import { IRequest } from '../interfaces/IRequest'
import { api, getAuthHeader } from '../utils/api'
import {
  IUploadImage,
  IVisitReport,
  IVisitReportForm,
  IVisitReportInformation,
} from '../interfaces/IVisitReports'
import { prepareContactTypesOptions } from '../utils/visitReports'
import { API_PATHS } from '../enums/apiPaths'
import { BASE_BACKEND_URL } from '../enums/common'
import { UPLOAD_IMAGE_FORM } from '../constants/form'
import { IArrayOptions } from '../interfaces/IAppointments'
import { timezoneOffset } from '../utils/helpers'

export async function createNewVisitReport(
  formData: IVisitReportForm
): Promise<number> {
  const params: IRequest = {
    endpoint: API_PATHS.visitReport,
    method: 'POST',
    body: JSON.stringify(formData),
  }
  const {
    body: { id },
  } = await api(params)

  return id
}

export async function getContactTypes(): Promise<IArrayOptions[]> {
  const params: IRequest = {
    endpoint: API_PATHS.contactTypes,
    method: 'GET',
  }

  const {
    body: { data },
  } = await api(params)

  return prepareContactTypesOptions(data)
}

export async function getClientLastVisitReportData(
  clientId: number
): Promise<IVisitReportInformation> {
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}${
      API_PATHS.lastVisitReport
    }?timezoneOffset=${timezoneOffset()}`,
    method: 'GET',
  }

  const {
    body: { data },
  } = await api(params)

  return data
}

export async function uploadImage(data: IUploadImage) {
  const formData = new FormData()
  UPLOAD_IMAGE_FORM.forEach((key) => {
    if (data[key as keyof IUploadImage]) {
      formData.append(key, data[key as keyof IUploadImage] as string | Blob)
    }
  })

  const params: IRequest = {
    endpoint: `${API_PATHS.file}${API_PATHS.upload}`,
    baseURL: BASE_BACKEND_URL,
    method: 'POST',
    body: formData,
    headers: getAuthHeader(),
  }

  return api(params)
}

export function getClientVisitReport(
  reportId: string
): Promise<{ body: IVisitReport }> {
  const params: IRequest = {
    endpoint: `/visit-reports/${reportId}`,
    method: 'GET',
    headers: getAuthHeader(),
  }

  return api(params)
}
